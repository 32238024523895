<!-- <ion-content #content> -->



  <div *ngIf="menu?.promotions && selectedCategory == undefined">
    <!-- <ion-item-divider mode="md">
      <ion-label>
        Kampanyalarımıza Göz Atın
      </ion-label>
    </ion-item-divider> -->

    <ion-slides [options]="slideOpts" pager="true" style="--bullet-background-active:black; max-height:295px;">
      <ion-slide color="dark" *ngFor="let item of menu.promotions">
        <img src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{item.image}}" alt="{{item?.description}}">
      </ion-slide>
    </ion-slides>
  </div>

  <!-- <div class="scrolling-wrapper">
    <div class="card" *ngFor="let item of menu.categories let i = index;" (click)="scrollTo('cat'+i)">
      <ion-card mode="ios" style="height: 100px; width: 100px;">
        <img style="max-height: 140px;" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{item.image}}" />
        <ion-card-content style="padding:0px;">
        </ion-card-content>
      </ion-card>
      <p style="text-align: center;">{{item.name | titlecase }}</p>
    </div>
  </div> -->

  <ion-grid *ngIf="selectedCategory == undefined;" class="fade-in">
    <ion-item-divider mode="md">
      <ion-label>
        {{ 'GLOBAL.CATEGORIES' | translate }}
      </ion-label>
    </ion-item-divider>
    <ion-row>
      <ion-col *ngFor="let item of menu.categories let i = index;">
        <ion-card mode="ios" button="true" style="margin: 6px;" (click)="selectCategory(item)">
          <ion-card-content class="catImgHolder">
            <img class="catImg" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{item.image}}" />
          </ion-card-content>
          <div class="catNameHolder">
            <p class="catName" [ngClass]="{'longName': item.name.length > 15}">{{item.name | titlecase }}</p>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>

  </ion-grid>

  <ion-list mode="ios" *ngIf="selectedCategory !== undefined;" class="fade-in">
    <ion-item-divider  mode="md" style="padding-left: 0px; height: 49px;" sticky="true">
      <!-- <ion-icon name="arrow-back-outline" color="danger" style="margin-right: 5px;"></ion-icon> -->
      <!-- <ion-icon name="chevron-back-outline" color="danger" style="margin-right: 5px;"></ion-icon>
      <ion-label color="danger">
        <h2 style="font-weight: bold;"> {{ 'GLOBAL.BACK' | translate}} </h2>
      </ion-label> -->
      <ion-buttons mode="ios" slot="start" style="margin-right: 5px; margin-top: 5px; margin-bottom: 5px;">
        <ion-button (click)="unselectCategory()" color="primary">
          <ion-icon name="chevron-back-outline"></ion-icon>
          {{ 'GLOBAL.BACK' | translate}}
        </ion-button>
      </ion-buttons>

      <ion-buttons mode="ios" slot="end" style="margin-right: 5px;">
        <ion-button color="primary">
          <ion-icon name="search-outline"></ion-icon>
        </ion-button>
      </ion-buttons>

    </ion-item-divider>
    <ion-item-group>

      <ion-list-header style="padding:0; max-height: 100px;">
        <img style="width: 100%; position: relative; top: 150px; filter:brightness(0.5);" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{selectedCategory?.image}}">
      </ion-list-header>



      <ion-item-divider mode="md">
        <h2 style="font-size: 25px; margin-top: 10px;">{{selectedCategory?.name | titlecase}} </h2>
        <!-- <p *ngIf="selectedCategory?.description">{{selectedCategory?.description}}</p> -->
        <!-- <ion-item lines="none">
          <ion-avatar slot="start">
            <img src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{selectedCategory?.image}}">
          </ion-avatar>
          <ion-label>
            <h1 style="font-weight: bold;">{{selectedCategory?.name | titlecase}} </h1>
            <p *ngIf="selectedCategory?.description">{{selectedCategory?.description}}</p>
          </ion-label>
        </ion-item> -->
      </ion-item-divider>

      <ng-container *ngFor="let product of selectedCategory?.items">
        <ng-container *ngIf="product?.image && !product.is_hidden && product.is_available">
          <ion-card style="margin-bottom: -20px; height: 250px;" (click)="productDetail(product)" button>
            <img style="min-width: 100%; position: relative; top: -20px;"  src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{product?.image}}">
          </ion-card>
        </ng-container>
        <ion-item *ngIf="!product.is_hidden" [disabled]="!product.is_available" (click)="productDetail(product)" button>
          <!-- <ion-avatar slot="start">
            <img *ngIf="product?.image" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{product?.image}}">
            <img *ngIf="!product?.image" class="storeLogo" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/kategori/default.png">
          </ion-avatar> -->

          <!-- <ion-avatar *ngIf="product?.image" slot="start">
            <img  src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{product?.image}}">
          </ion-avatar> -->
          <ion-label>{{product.name | titlecase }}
            <p style="white-space: pre-wrap;">{{ product.description }}</p>
            <p *ngIf="!product.is_available"> {{ 'GLOBAL.NA' | translate }}</p>
            <p *ngIf="product.is_veggy">🌿 Vegan / Vejetaryan</p>
            <p *ngIf="product.is_suggested">👩‍🍳 Şefin Tafsiyesi</p>
          </ion-label>
          <ion-note *ngIf="!product.options || product?.options.length == 0" slot="end" color="dark" size="large">{{product.price | price }}</ion-note>
          <ion-note *ngIf="product.options && product?.options.length > 0" slot="end" color="dark" size="large">
            <p *ngFor="let opts of product.options" style="text-align: end;"><small>{{opts.name}}:</small> {{opts.price | price }} </p>
          </ion-note>
        </ion-item>
      </ng-container>


      <div *ngFor="let group of selectedCategory?.item_groups">

        <ion-item-divider mode="md" color="dark">
          <ion-label style="font-weight: bold;">{{group.name | titlecase }}</ion-label>
        </ion-item-divider>

        <ng-container *ngFor="let product of group.items">
          <ng-container *ngIf="product?.image && !product.is_hidden && product.is_available">
            <ion-card style="margin-bottom: -20px; height: 250px;" (click)="productDetail(product)" button>
              <img style="min-width: 100%; position: relative; top: -20px;"  src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{product?.image}}">
            </ion-card>
          </ng-container>
          <ion-item *ngIf="!product.is_hidden" [disabled]="!product.is_available" (click)="productDetail(product)" button>
            <!-- <ion-avatar slot="start">
              <img *ngIf="product?.image" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{product?.image}}">
              <img *ngIf="!product?.image" src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/kategori/default.png">
            </ion-avatar> -->
          <!-- <ion-avatar *ngIf="product?.image" slot="start">
            <img  src="https://cdn.quickly.com.tr/quickly-menu/{{menu.slug}}/{{product?.image}}">
          </ion-avatar> -->
            <ion-label>{{product.name | titlecase }}
              <p>{{ product.description }}</p>
              <p *ngIf="!product.is_available"> {{ 'GLOBAL.NA' | translate }}</p>
              <p *ngIf="product.is_veggy">🌿 Vegan / Vejetaryan</p>
              <p *ngIf="product.is_suggested">👩‍🍳 Şefin Tafsiyesi</p>
            </ion-label>
            <ion-note *ngIf="!product.options || product?.options.length == 0" slot="end" color="dark" size="large">{{product.price | price }}</ion-note>
            <ion-note *ngIf="product.options && product?.options.length > 0" slot="end" color="dark" size="large">
              <p *ngFor="let opts of product.options" style="text-align: end;"><small>{{opts.name}}:</small> {{opts.price | price }} </p>
            </ion-note>
          </ion-item>
        </ng-container>
      </div>
    </ion-item-group>
  </ion-list>
<!-- </ion-content> -->
