import { Component, Input, OnInit } from '@angular/core';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'store-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit {

  @Input('menu') menu: any;
  @Input('store') store: any;

  constructor() { }

  ngOnInit() {}

}
