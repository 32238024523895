

<ion-card mode="ios">

  <!-- <ion-item lines="none" color="white">
    <img slot="start" width="60" class="storeLogo" [src]="store.logo">
    <ion-label>
      <h2 style="font-weight: bold; font-size: 15px;">{{store.name}}</h2>
      <p>{{store.motto}}</p>
    </ion-label>
  </ion-item> -->
  <ion-item  lines="full">
    <!-- <ion-icon name="beer-outline" slot="start"></ion-icon>
    <ion-icon name="call-outline" slot="start"></ion-icon> -->
    <ion-icon name="restaurant-outline" slot="start"></ion-icon>
    <ion-label>Rezervasyon</ion-label>
    <ion-button href="tel:0{{store.phone_number}}" fill="outline" slot="end">{{ 'INFO.CALL' | translate }}</ion-button>
  </ion-item>

  <ion-card-header style="text-align: center;">

    <!-- <img width="75%" class="storeLogo" [src]="store.logo"> -->
    <ion-label>
      <!-- <h2 style="font-weight: bold; font-size: 15px;">"{{store.motto}}"</h2>
      <p><small>{{store.address.description}}</small></p>
      <p><span>0 {{store.phone_number | mask: '(000) 000 00 00'}}</span></p> -->
    </ion-label>
  </ion-card-header>

  <ion-list inset="true">
    <ion-accordion-group>
      <ion-accordion value="start">
        <ion-item slot="header">
          <ion-label>Starts</ion-label>
          <ion-note slot="end">TEST</ion-note>
        </ion-item>
        <ion-datetime slot="content" presentation="date-time" ></ion-datetime>
      </ion-accordion>
      <ion-accordion value="end">
        <ion-item slot="header">
          <ion-label>Ends</ion-label>
          <ion-note slot="end"></ion-note>
        </ion-item>
        <ion-datetime slot="content" presentation="date-time" ></ion-datetime>
      </ion-accordion>
    </ion-accordion-group>
    <ion-item>
      <ion-label>Repeat</ion-label>
      <ion-note slot="end">Never</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Travel Time</ion-label>
      <ion-note slot="end">None</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Alert</ion-label>
      <ion-note slot="end">None</ion-note>
    </ion-item>
  </ion-list>

</ion-card>



<!-- <ion-datetime locale="tr-TR"></ion-datetime> -->

<!-- <ion-datetime hour-cycle="h12" locale="en-GB"></ion-datetime> -->